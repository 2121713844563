<template>
    <footer>
        <div class="layout">
            <div class="footer_pack">
                <div class="footer_logo">
                    <router-link to="/">
                        <img :src="detail.picture" alt="logo">
                        <!-- <div class="logo_cont">
                            <h3>南合协会官方网站</h3>
                            <p>Official website of Nanhe Association</p>    
                        </div>  -->
                    </router-link>  
                    <p class="logo_desc">
                        {{detail.s01}}    
                    </p>  
                </div> 
                <div class="footer_contact" v-if="langs == 'zh_cn'">
                    <p>工作邮箱：{{detail.l01}}</p>
                    <p>联系地址：{{detail.l02}}</p>  
                </div>
                <div class="footer_contact" v-else>
                    <p>Work email: {{detail.l01}}</p>
                    <p>Contact address: {{detail.l02}}</p>  
                </div>
                <div class="footer_right">
                    <div class="footer_code">
                        <img :src="detail.thumbnail" alt="code">
                        <p>{{detail.s08}}</p>
                    </div>
                    <ul class="footer_links">
                        <li v-for="item in linkList" :key="item.id">
                            <a :href="item.linkurl">
                                <img :src="item.picture" :alt="item.title">
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="copyright" v-html="detail.detailcontent">
            
        </div>
    </footer>
</template>

<script>
export default {
    data(){
        return{
            // url:'https://test21.icesnow-test.top',
            detail:{},
            linkList:[],
            langs:'zh_cn'
        }
    },
    methods: {
        getData(parameteradd,tc,lang){
            return this.$axios.get(parameteradd,{
                        params:{
                            typecode:tc,
                            language:lang
                        }
                    })
        },
        getListData(parameteradd,tc,page,pagenum,l){
            return this.$axios.get(parameteradd,{
                        params:{
                            page:page,
                            limit:pagenum,
                            typecode:tc,
                            language:l
                        }
                    })
        }
    },
    mounted() {
        var _this = this;
         if(_this.$Cookie.get('language') == "" || _this.$Cookie.get('language') == null || _this.$Cookie.get('language') == "undefined"){
            _this.langs = "zh_cn"
        }else{
             _this.langs = _this.$Cookie.get('language')
        }
        
       _this.getData('/Article/GetArticleDetailByTc',"WebSite",_this.langs).then((res)=>{
            _this.detail = res.data.data
            _this.detail.picture = _this.url + res.data.data.picture;
            _this.detail.thumbnail = _this.url + res.data.data.thumbnail;
        })
        _this.getListData('/Article/GetArticleList','links',1,10,"",_this.langs).then((res)=>{
            _this.linkList = res.data.data;
            _this.linkList.forEach((k,i) => {
                _this.linkList[i].picture = _this.url + k.picture
            });
        })
    },
}
</script>

<style>

</style>