import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/Home.vue'
import AboutUss from '../views/AboutUs.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/AboutUs',
    name: 'AboutUs',
    component: AboutUss
  },
  {
    path: '/Expert',
    name: 'Expert',
    component: function () {
      return import('../views/Expert.vue')
    }
  },
  {
    path: '/ExpertDetail',
    name: 'ExpertDetail',
    component: function () {
      return import('../views/ExpertDetail.vue')
    }
  },
  {
    path: '/union',
    name: 'union',
    component: function () {
      return import('../views/union.vue')
    }
  },
  {
    path: '/constitution',
    name: 'constitution',
    component: function () {
      return import('../views/constitution.vue')
    }
  },
  {
    path: '/join',
    name: 'join',
    component: function () {
      return import('../views/join.vue')
    }
  },
  {
    path: '/unionDetail',
    name: 'unionDetail',
    component: function () {
      return import('../views/unionDetail.vue')
    }
  },
  {
    path:"/News",
    name:'News',
    component:()=>{
      return import('@/views/News.vue')
    }
  },
  {
    path:"/NewsDetail",
    name:'NewsDetail',
    component:()=>{
      return import('@/views/NewsDetail.vue')
    }
  },
  {
    path:"/publicDetail",
    name:'publicDetail',
    component:()=>{
      return import('@/views/publicDetail.vue')
    }
  },
  {
    path:'/Industry',
    name:'Industry',
    component:()=>{
      return import('@/views/Industry.vue')
    }
  },
  {
    path:'/IndustryDetail',
    name:'IndustryDetail',
    component:()=>{
      return import('@/views/IndustryDetail.vue')
    }
  },
  {
    path:'/Products',
    name:'Products',
    component:()=>{
      return import('@/views/Products.vue')
    }
  },
  {
    path:'/ProductsDetail',
    name:'ProductsDetail',
    component:()=>{
      return import('@/views/ProductsDetail.vue')
    }
  },
  {
    path:'/Resources',
    name:'Resources',
    component:()=>{
      return import('@/views/Resources.vue')
    }
  },
  {
    path:'/ResourcesDetail',
    name:'ResourcesDetail',
    component:()=>{
      return import('@/views/ResourcesDetail.vue')
    }
  },
  {
    path:'/ContactUs',
    name:'ContactUs',
    component:()=>{
      return import('@/views/ContactUs.vue')
    }
  },
  {
    path:'/Search',
    name:'Search',
    component:()=>{
      return import('@/views/Search.vue')
    }
  }
]

const router = new VueRouter({
  mode: 'hash',
  // base: process.env.BASE_URL,
  routes
})

export default router
