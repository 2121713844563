<template>
    <header>

        <div class="header_top">
            <div class="layout">
                <p>{{detail.s06}}</p>
                <div class="Member_entrance" @click="loginshow = true">
                    <p >{{logintext}}</p>
                    <div class="login_success" v-show="success_show">
                        <p>
                            <span>用户手机</span>
                            <i class="bxweb bx-xia"></i>
                        </p>
                        <div class="login_success_btn">
                            <div @click="success"> 
                                <img src="../../public/images/sign_out.png" alt="">
                                <span>退出</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="header_center">
            <div class="layout">
                <div class="header_center_pack">
                    <div class="logo">
                        <router-link to="/">
                            <img :src="detail.picture" alt="logo">
                            <!-- <div class="logo_cont">
                                <h3>南合协会官方网站</h3>
                                <p>Official website of Nanhe Association</p>    
                            </div>  -->
                        </router-link>
                    </div>
                    <div class="header_search" v-show="hides">
                        <input type="text"  :placeholder="TipsSearch" @keyup.enter="search" v-model="val">
                        <div class="header_search_icon" @click="search()">
                            <img src="../../public/images/sousuo.png">    
                        </div>    
                    </div> 
                </div>
                <div class="iconfont">
                    <i class="cha" @click="moveicon = true" v-show="!moveicon"></i>
                    <i class="nav" @click="moveicon = false" v-show="moveicon"></i>
                </div>
            </div>
        </div>
        <div class="navlist" v-show="!moveicon">
            <div class="layout">
                <ul class="w_nav" v-if="langs == 'zh_cn'">
                    <li>
                        <a href="javascript:;"><span>{{langtexts}}</span> <i class="bxweb bx-xia"></i></a>
                        <ul class="er_nav">
                            <li v-for="(item,index) in language" :key="index" @click="languageTest(item.lang,item.langType)">
                                <a href="javascript:;">{{item.text}}</a>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <router-link to="/"><span>首页</span></router-link>
                    </li>
                    <li>
                        <a href="javascript:;"><span>关于我们</span><i class="bxweb bx-xia"></i></a>
                        <ul class="er_nav">
                            <li>
                                <router-link to="/AboutUs" active-class="active">协会简介</router-link>
                            </li>
                            <li>
                                <router-link to="/constitution" active-class="active">协会章程</router-link>
                            </li>
                            <li>
                                <router-link to="/Expert" active-class="active">专家智库</router-link>
                            </li>
                            <li>
                                <router-link to="/union" active-class="active">联盟成员</router-link>
                            </li>
                            <li>
                                <router-link to="/join" active-class="active">申请加入</router-link>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <a href="javascript:;"><span>新闻中心</span><i class="bxweb bx-xia"></i></a>
                        <ul class="er_nav">
                            <li v-for="(item,index) in newsclassifylist" :key="item.id">
                                <router-link :to="newsUrl+item.id+publicindex+index+publicname+item.name">{{item.name}}</router-link>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <a href="javascript:;"><span>产业展示</span><i class="bxweb bx-xia"></i></a>
                        <ul class="er_nav">
                            <li v-for="(item,index) in industryclassifylist" :key="item.id">
                                <router-link :to="industryUrl+item.id+publicindex+index+publicname+item.name">{{item.name}}</router-link>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <router-link to="/Products"><span>技术产品展示</span></router-link>
                    </li>
                    <li>
                        <a href="javascript:;"><span>资源专区</span><i class="bxweb bx-xia"></i></a>
                        <ul class="er_nav">
                            <li v-for="(item,index) in Resourcesclassifylist" :key="item.id">
                                <router-link :to="ResourcesUrl+item.id+publicindex+index+publicname+item.name">{{item.name}}</router-link>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <router-link to="/ContactUs"><span>联系我们</span></router-link>
                    </li>
                </ul>
                <ul class="w_nav" v-else>
                    <li>
                        <a href="javascript:;"><span>{{langtexts}}</span> <i class="bxweb bx-xia"></i></a>
                        <ul class="er_nav">
                            <li v-for="(item,index) in language" :key="index" @click="languageTest(item.lang,item.langType)">
                                <a href="javascript:;">{{item.text}}</a>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <router-link to="/"><span>Home</span></router-link>
                    </li>
                    <li>
                        <a href="javascript:;"><span>AboutUs</span><i class="bxweb bx-xia"></i></a>
                        <ul class="er_nav">
                            <li>
                                <router-link to="/AboutUs" active-class="active">Brief introduction</router-link>
                            </li>
                            <li>
                                <router-link to="/constitution" active-class="active">Constitution</router-link>
                            </li>
                            <li>
                                <router-link to="/Expert" active-class="active">Expert think tank</router-link>
                            </li>
                            <li>
                                <router-link to="/union" active-class="active">Alliance members</router-link>
                            </li>
                            <li>
                                <router-link to="/join" active-class="active">Join</router-link>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <a href="javascript:;"><span>News</span><i class="bxweb bx-xia"></i></a>
                        <ul class="er_nav">
                            <li v-for="(item,index) in newsclassifylist" :key="item.id">
                                <router-link :to="newsUrl+item.id+publicindex+index+publicname+item.name">{{item.name}}</router-link>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <a href="javascript:;"><span>Industry Display</span><i class="bxweb bx-xia"></i></a>
                        <ul class="er_nav">
                            <li v-for="(item,index) in industryclassifylist" :key="item.id">
                                <router-link :to="industryUrl+item.id+publicindex+index+publicname+item.name">{{item.name}}</router-link>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <router-link to="/Products"><span>Products</span></router-link>
                    </li>
                    <li>
                        <a href="javascript:;"><span>Resource Zone</span><i class="bxweb bx-xia"></i></a>
                        <ul class="er_nav">
                            <li v-for="(item,index) in Resourcesclassifylist" :key="item.id">
                                <router-link :to="ResourcesUrl+item.id+publicindex+index+publicname+item.name">{{item.name}}</router-link>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <router-link to="/ContactUs"><span>ContactUs</span></router-link>
                    </li>
                </ul>
            </div>
        </div>
        <div class="login" v-show="loginshow">
            <div class="login_pack">
                <div class="close" @click="close()">+</div>
                <div class="login_title" v-if="langs == 'zh_cn'">
                    手机号验证码登录
                </div>
                <div class="login_title" v-else>
                    Mobile number verification code login
                </div>
                <div class="login_cont">
                    <div class="login_form">
                        <div class="login_input">
                            <select name="" id="">
                                <option value="+86">+86</option>
                            </select>
                            <input type="text" @blur="verification" v-model="tel" :placeholder="TipsPhone">
                        </div>
                        <div class="login_code">
                            <input type="text"  v-model="code" :placeholder="TipsCode">
                            <div class="login_codenum">{{count_down}}</div>     
                        </div> 
                        <p class="Warning_language" v-show="hide">{{Warning}}</p>
                    </div>
                    <div class="login_btn" v-if="langs == 'zh_cn'">登录/注册 </div>
                    <div class="login_btn" v-else>Login/Register</div>
                      
                    <div class="automatic_logon">
                        <input type="checkbox" id='c'>
                        <label for="c" v-if="langs == 'zh_cn'">下次自动登录</label>    
                        <label for="c" v-else>Next automatic login</label>    
                    </div> 
                </div>
            </div>
        </div>
    </header>
</template>

<script>
// import {EventBus} from '@/event-bus.js'
 export default {
    data(){
        return{
            // url:'https://test21.icesnow-test.top',
            TipsPhone:'请输入手机号',
            TipsCode:'请输入手机验证码',
            TipsSearch:'请输入关键词',
            detail:{},
            newsclassifylist:{},
            industryclassifylist:{},
            Resourcesclassifylist:{},
            newsUrl:'/News?listnum=',
            industryUrl:'/Industry?listnum=',
            ResourcesUrl:'/Resources?listnum=',
            publicindex:'&index=',
            publicname:'&name=',
            loginshow:false,
            Warning:'手机动态码输入错误',
            tel:'',
            code:'',
            hide:false,
            success_show:false,
            logintext:'登录 | 注册',
            count_down:'获取验证码',
            language:[
                {
                    text:'繁体中文',
                    lang:'zh_cn',
                    langType:"t",
                },
                {
                    text:'EN',
                    lang:'en_us',
                    langType:"s",
                }
            ],
            val:'',
            hides:true,
            moveicon:true,
            langtexts:'简体中文',
            langs:'zh_cn'
        }
    },
    methods: {
        getData(parameteradd,tc,l){
            return this.$axios.get(parameteradd,{
                        params:{
                            typecode:tc,
                            language:l
                        }
                    })
        },
        getListData(parameteradd,tc,page,pagenum,ids,l){
            return this.$axios.get(parameteradd,{
                        params:{
                            page:page,
                            limit:pagenum,
                            typecode:tc,
                            categoryid:ids,
                            language:l
                        }
                    })
        },
        close(){
            this.loginshow = false
        },
        search(){
            // 搜索事件
            if(this.val == ""){
                if(this.langs == "zh_cn"){
                    alert('请输入搜索内容')
                }else {
                    alert('Please enter search content')
                }
            }else {
                this.$router.replace({
                    path:'/Search?listnum='+this.val
                })
            }
        },
        verification(){
            // 登录注册事件
            if(this.tel == "") {
                this.hide=true
                if(this.langs == "zh_cn") {
                    this.Warning = "Please enter your phone number"
                }else {
                    this.Warning = ''
                }
            }else if(/^(13[0-9]|14[5|7|9]|15[0-3|5-9]|16[2|5|6|7]|17[0-8]|18[0-9]|19[8|9])\d{8}$/.test(this.tel)) {
                this.Warning=""
                this.hide=false
            }else {
                if(this.langs == "zh_cn") {
                    this.Warning = '请输入正确的手机号'
                }else {
                    this.Warning = 'Please enter the correct phone number'
                }
                this.hide=true
            }
        },
        success(){
            // 退出事件
        },

        //语言切换
        languageTest(e,type){
            this.$Cookie.set('language',e)
            this.$Cookie.set('langtype',type)

            if(this.$route.path != "/") {
                // this.$router.replace({
                //     path:'/'
                // })
                window.location.href = "/"
            }else{
                window.location.reload()
            }

    
           
        }
    },
    created() {
        // EventBus.$on('valueChanged', (value) => {
        //     this.hides = value;
        // });
    },
    mounted() {
        var _this = this;
        if(_this.$Cookie.get('language') == "" || _this.$Cookie.get('language') == null || _this.$Cookie.get('language') == "undefined"){
            _this.langs = "zh_cn"
        }else{
             _this.langs = _this.$Cookie.get('language')
        }

        

            if(_this.langs == 'zh_cn') {
                if(this.$Cookie.get('langtype') == 't') {
                    this.langtexts = '繁体中文'
                    this.language[0].text = '简体中文'
                    this.language[0].langType = 's'
                    setTimeout(()=>{
                         this.$zhTran()
                    },1000)
                }else {
                    this.langtexts = '简体中文'
                    this.language[0].text = '繁体中文'
                    this.language[0].langType = 't'
                     setTimeout(()=>{
                         this.$zhTran()
                    },1000)
                }
            }else {
                this.langtexts = 'EN' 
                this.language[0].text = '简体中文'
                 this.language[0].langType = 's'
                this.Warning = 'Mobile dynamic code input error'
                this.logintext = 'Login | Register'
                this.count_down = 'Verification Code'
                this.TipsPhone = 'Please enter your phone number'
                this.TipsCode = "Please enter the verification code"
                this.TipsSearch = 'Please enter keywords'
            }
            

        if(window.innerWidth > 1000) {
            _this.moveicon = false
        }else {
            _this.moveicon = true
        }
       _this.getData('/Article/GetArticleDetailByTc',"WebSite",_this.langs).then((res)=>{
            _this.detail = res.data.data
            _this.detail.picture = _this.url + res.data.data.picture
        })
        _this.getListData('/ArticleCategory/GetArticleCategoryList','News',1,10,"",_this.langs).then((res)=>{
            _this.newsclassifylist = res.data.data;
        });
        _this.getListData('/ArticleCategory/GetArticleCategoryList','Industry',1,10,"",_this.langs).then((res)=>{
            _this.industryclassifylist = res.data.data;
        });
        _this.getListData('/ArticleCategory/GetArticleCategoryList','Resources',1,10,"",_this.langs).then((res)=>{
            _this.Resourcesclassifylist = res.data.data;
        });
        
        }
    }
//    updated() {
//     var _this = this
//     if(_this.langs == 'zh_cn') {
//         if(this.$Cookie.get('langtype') == 't') {
//             this.langtexts = '繁体中文'
//             this.language[0].text = '简体中文'
//             this.$Cookie.set('langtype','t')
//             this.$zhTran()
//         }else {
//             this.langtexts = '简体中文'
//             this.language[0].text = '繁体中文'
//             this.$Cookie.set('langtype','s')
//             this.$zhTran()
//         }
//     }else {
//     this.langtexts = 'EN' 
//     this.language[0].text = '简体中文'
//         this.$Cookie.set('langtype','s')
//     this.Warning = 'Mobile dynamic code input error'
//         this.logintext = 'Login | Register'
//         this.count_down = 'Verification Code'
//         this.TipsPhone = 'Please enter your phone number'
//         this.TipsCode = "Please enter the verification code"
//         this.TipsSearch = 'Please enter keywords'
//     }
    
                
// }
//  }
</script>


<style scoped >

</style>
